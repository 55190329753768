<template>
  <v-btn icon :color="color" class="mr-3" large @click.stop="$emit('click')">
    <v-icon>arrow_back</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "white",
    },
  },
};
</script>

<style></style>
