import "@babel/polyfill";
import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@/registerServiceWorker";
import "@/components/shared";
import "@/utils/filters";
import "@/plugins/toasted";
import "@/plugins/vue-the-mask";
import "@/misc";

import "@/styles/custom.css";

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
