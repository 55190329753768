<template>
  <v-dialog
    :value="loading"
    persistent
    fullscreen
    content-class="dialog-loading"
    transition="false"
    style="z-index: 9999"
  >
    <v-container fill-height>
      <v-layout row justify-center align-center>
        <trinity-rings-spinner
          :animation-duration="1500"
          :size="66"
          color="white"
        />
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import { TrinityRingsSpinner } from "epic-spinners";
export default {
  name: "LoadingDialog",
  components: {
    TrinityRingsSpinner,
  },
  computed: {
    loading() {
      return this.$store.state.loading.dialog;
    },
  },
};
</script>

<style>
.dialog-loading {
  background-color: #303030;
  opacity: 0.7;
}
</style>
