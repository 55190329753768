const state = {
  loading: false,
  dialog: false,
};

const mutations = {
  start(state) {
    state.loading = true;
  },

  stop(state) {
    state.loading = false;
  },

  setDialog(state, value) {
    state.dialog = value;
  },
};

const actions = {
  async openDialog(context) {
    await context.commit("setDialog", true);
  },

  async closeDialog(context) {
    await context.commit("setDialog", false);
  },
};

const getters = {
  isLoading(state) {
    return state.loading;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
