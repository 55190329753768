<template>
  <div class="d-flex align-center justify-start">
    <table-action-button
      text="Subir posição"
      @click="emit('DOWN')"
      icon="keyboard_arrow_up"
    />
    <span>{{ number }}</span>
    <table-action-button
      text="Abaixar posição"
      @click="emit('UP')"
      icon="keyboard_arrow_down"
    />
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: [Number, String],
      default: "",
    },
  },
  methods: {
    emit(direction) {
      this.$emit("change", direction);
    },
  },
};
</script>

<style></style>
