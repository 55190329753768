import Vue from "vue";
import Router from "vue-router";
import menu from "@/menu";
import store from "@/store";
import { pagesNames } from "@/router/pages-names";

Vue.use(Router);

/* export default new Router({
  routes: [
    {
      path: "/",
      name: "Home",
      component: () => import("@/pages/Home")
    }
  ]
}); */

const router = new Router({
  routes: [
    { path: "/", redirect: { name: pagesNames.LOGIN } },
    ...menu,
    {
      path: "*", // or '/index.html'
      beforeEnter: (to, from, next) => {
        next("/");
      },
    },
  ],
  mode: "history",
});

router.beforeEach((to, from, next) => {
  const isLogged = store.getters["auth/isLogged"];
  //const perms = store.getters["login/perms"];
  if (to.meta.requiresAuth) {
    if (isLogged) {
      next();
    } else {
      // Se não tiver permissão, vai para a página de login
      next({ name: pagesNames.LOGIN });
    }
  } else {
    if (to.name === pagesNames.LOGIN && isLogged) {
      next({ name: pagesNames.HOME });
    }
    next();
  }
});

export default router;
