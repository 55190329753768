import moment from "moment";
import Vue from "vue";
import { propertyToFriendlyName } from "@/misc/therapy-template-properties";

Vue.filter("formatDate", (date) => {
  if (!date) return "";
  return moment(date).format("DD/MM/YYYY");
});

Vue.filter("formatDateTime", (date) => {
  if (!date) return "";
  return moment(date).format("DD/MM/YYYY HH:mm:ss");
});

Vue.filter("transformPointType", (value) => {
  const map = new Map([
    [0, "Rastreio"],
    [1, "Impactação"],
  ]);
  return map.get(Number(value));
});

Vue.filter("booleanToString", (boolean) => {
  return boolean ? "Sim" : "Não";
});

Vue.filter("transformStatusAppointment", (value) => {
  const mapConditions = new Map([
    [1, "Sem paciente"],
    [2, "Aguardando confirmação"],
    [3, "Confirmado"],
    [4, "Finalizado"],
    [5, "Cancelado"],
  ]);
  return mapConditions.get(Number(value));
});

Vue.filter("transformCancellationSourceAppointment", (value) => {
  const map = new Map([
    ["patient", "Paciente"],
    ["therapist", "Terapeuta"],
  ]);
  return map.get(value);
});

Vue.filter("propertyToFriendlyName", propertyToFriendlyName);
