<template>
  <v-card
    id="perseu-card"
    :class="`elevation-1 padding-bigger ${cardClass}`"
    d-block
  >
    <v-card-title class="primary">
      <slot name="title-left"></slot>
      <span :class="`title  + ${classTitle}`">{{ title }}</span>
      <v-spacer></v-spacer>
      <slot name="title-right"></slot>
    </v-card-title>
    <v-card-text class="mt-3">
      <slot name="content"></slot>
    </v-card-text>
    <v-layout justify-center>
      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  props: {
    title: String,
    color: { type: String, default: "secondary" },
    classTitle: { type: String, default: "white--text" },
    cardClass: String,
  },
};
</script>

<style scoped>
#perseu-card {
  padding-bottom: 20px;
}
</style>
