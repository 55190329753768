import Vue from "vue";

const errorHandler = (axiosError) => {
  console.error(axiosError);
  if (
    axiosError.response &&
    axiosError.response.status === 400 &&
    axiosError.response.data
  ) {
    Vue.prototype.$toasted.global.error({
      message: axiosError.response.data.message,
    });
  } else {
    // eslint-disable-next-line no-console
    Vue.prototype.$toasted.global.defaultError();
  }
};

Vue.prototype.$errorHandler = errorHandler;
