import axios from "axios";
import store from "@/store";
import Vue from "vue";

const api = () => {
  let token = store.getters["auth/token"] || "";
  let api = axios.create({
    baseURL: process.env.VUE_APP_API || "http://192.168.25.19:3000",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  api.interceptors.request.use(
    (conf) => {
      store.commit("loading/start");
      return Promise.resolve(conf);
    },
    (error) => {
      store.commit("loading/stop");
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (conf) => {
      store.commit("loading/stop");
      return Promise.resolve(conf);
    },
    (error) => {
      // console.log(JSON.stringify(error, null, 2));
      if (error?.response?.status === 401) {
        Vue.toasted.global.error({
          message: "Sessão expirada. Faça o login novamente!",
        });
        store.dispatch("auth/logout");
      }
      store.commit("loading/stop");
      return Promise.reject(error);
    }
  );

  return api;
};

export default api;
