export const therapyTemplateProperties = {
  TYPE: "TYPE",
  CATEGORY: "CATEGORY",
  PATHOGEN: "PATHOGEN",
  SYMPTOM: "SYMPTOM",
};

export const propertyToFriendlyName = (property) => {
  const objectConditions = {
    [therapyTemplateProperties.TYPE]: "Tipo",
    [therapyTemplateProperties.CATEGORY]: "Categoria",
    [therapyTemplateProperties.PATHOGEN]: "Patógeno",
    [therapyTemplateProperties.SYMPTOM]: "Sintoma",
  };
  return objectConditions[property];
};

export const propertiesWithNameArray = () => {
  return Object.keys(therapyTemplateProperties).map(k => ({
    label: propertyToFriendlyName(k),
    value: k
  }));
}
