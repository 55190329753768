<template>
  <v-app id="app">
    <header>
      <v-app-bar app v-if="isLogged" color="blue-grey darken-4" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer">
          <v-btn icon>
            <v-icon>menu</v-icon>
          </v-btn>
        </v-app-bar-nav-icon>
        <v-toolbar-title>
          <v-img
            src="/img/logo-texto-verde-admin.png"
            :aspect-ratio="1"
            width="600"
            height="40"
            contain
            position="left"
            @click="redirect"
            class="image-link"
          ></v-img>
        </v-toolbar-title>
        <env-chip />
      </v-app-bar>
      <v-card>
        <v-navigation-drawer
          v-if="isLogged"
          v-model="drawer"
          app
          enable-resize-watcher
        >
          <v-list dense>
            <v-list-item :to="{ name: homeItem.name }">
              <v-list-item-icon>
                <v-icon>{{ homeItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ homeItem.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="m in loadMenu"
              :key="m.name"
              :to="{ name: m.name }"
            >
              <v-list-item-icon>
                <v-icon>{{ m.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ m.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <template v-slot:append>
              <v-list-item @click="logout">
                <v-list-item-icon>
                  <v-icon>exit_to_app</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Sair</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-navigation-drawer>
      </v-card>
    </header>
    <v-main class="v-content grey lighten-3">
      <router-view></router-view>
    </v-main>
    <loading />
  </v-app>
</template>

<script>
import menu, { sortMenu, homeItem } from "@/menu";
import Loading from "@/components/layout/Loading";

export default {
  name: "app",
  components: {
    Loading,
  },
  data: () => ({
    appName: "Par Magnético - Administrador",
    drawer: true,
  }),
  computed: {
    loadMenu() {
      return menu.filter((m) => m.visible).sort(sortMenu);
    },
    homeItem() {
      return homeItem;
    },
    loading() {
      return (
        this.$store.getters["loading/isLoading"] &&
        this.$store.getters["loading/isActive"]
      );
    },
    isLogged() {
      return this.$store.getters["auth/isLogged"];
    },
  },
  methods: {
    redirect() {
      this.$router.push({ path: "/" });
    },
    logout() {
      this.drawer = false;
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>

<style>
body {
  font-family: Roboto, sans-serif;
}

.dialog-loading {
  background-color: hsl(0, 0%, 19%);
  opacity: 0.7;
}

.image-link {
  cursor: pointer;
}
</style>
