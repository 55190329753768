<template>
  <div
    class="mb-3 body-2 grey--text text--darken-3 d-flex align-center flex-wrap"
  >
    <v-icon v-if="icon" color="secondary" class="mr-2">{{ icon }}</v-icon>
    <span class="font-weight-bold mr-1">{{ property }}: </span>
    <span v-if="!$slots.value">{{ value }}</span>
    <slot v-else name="value" />
  </div>
</template>

<script>
export default {
  name: "PropertyValue",
  props: {
    property: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
