import router from "@/router";
import { pagesNames } from "@/router/pages-names";

const state = {
  token: JSON.parse(window.localStorage.getItem("par_token")),
  roles: JSON.parse(window.localStorage.getItem("par_roles")),
};

const mutations = {
  login(state, { token, roles }) {
    window.localStorage.setItem("par_token", JSON.stringify(token));
    window.localStorage.setItem("par_roles", JSON.stringify(roles));
    state.token = token;
    state.roles = roles;
  },

  logout(state) {
    window.localStorage.removeItem("par_token");
    window.localStorage.removeItem("par_roles");
    state.token = undefined;
    state.roles = undefined;
  },
};

const actions = {
  async login({ commit }, { token, roles }) {
    commit("login", { token, roles });
    router.push({ name: pagesNames.HOME });
  },

  async logout({ commit }) {
    await commit("logout");
    router.push({ name: pagesNames.LOGIN });
  },
};

const getters = {
  isLogged(state) {
    return !!state.token;
  },
  token(state) {
    return state.token;
  },
  roles(state) {
    return state.roles;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
