<template>
  <v-select
    :value="value"
    label="Idioma"
    :items="langs"
    item-text="name"
    return-object
    :rules="rules"
    @change="emitInput"
    :loading="isLoading"
  />
</template>

<script>
import { findAll as findAllLangs } from "@/services/langs-service";
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    langs: [],
  }),
  computed: {
    isLoading() {
      return this.$store.getters["loading/isLoading"];
    },
  },
  created() {
    this.loadLangs();
  },
  methods: {
    async loadLangs() {
      try {
        const { data } = await findAllLangs();
        this.langs = data;
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    emitInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style></style>
