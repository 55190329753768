import Vue from "vue";

const optionsDefaultState = () => ({
  page: 1,
  itemsPerPage: 10,
  sortBy: ["id"],
  sortDesc: [false],
  multiSort: true,
  mustSort: true,
});

const filtersDefaultState = () => ({
  active: true,
  name: "",
  trackingPoint: "",
  impactionPoint: "",
  type: "",
  category: "",
  symptom: "",
  pathogen: "",
});

const state = {
  filters: filtersDefaultState(),
  options: optionsDefaultState(),
};

const mutations = {
  setFilters(state, value) {
    state.filters = value;
  },
  setPropertyFilters(state, { value, property }) {
    Object.assign(state.filters, { [property]: value });
  },
  setOptions(state, value) {
    state.options = value;
  },
  clearFilters(state) {
    state.filters = filtersDefaultState();
  },
  clearOptions(state, value) {
    state.options = optionsDefaultState();
  },
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
