import Vue from "vue";
import PerseuCard from "@/components/shared/PerseuCard";
import SelectLang from "@/components/shared/SelectLang";
import PerseuTableActionButton from "@/components/shared/PerseuTableActionButton";
import NumberArrows from "@/components/shared/NumberArrows";
import FiltersPanel from "@/components/shared/FiltersPanel";
import EnvChip from "@/components/shared/EnvChip";
import BackButton from "@/components/shared/BackButton";
import PropertyValue from "@/components/shared/PropertyValue";
import EmptyList from "@/components/shared/EmptyList";

Vue.component("perseu-card", PerseuCard);
Vue.component("select-lang", SelectLang);
Vue.component("table-action-button", PerseuTableActionButton);
Vue.component("number-arrows", NumberArrows);
Vue.component("filters-panel", FiltersPanel);
Vue.component("env-chip", EnvChip);
Vue.component("back-button", BackButton);
Vue.component("property-value", PropertyValue);
Vue.component("empty-list", EmptyList);
