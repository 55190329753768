<template>
  <div class="d-flex flex-column text-center align-center py-5">
    <v-icon x-large :color="iconColor">{{ icon }}</v-icon>
    <p class="mt-3 subtitle-2">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "EmptyList",
  props: {
    text: {
      type: String,
      default: "Nenhum resultado encontrado!",
    },
    icon: {
      type: String,
      default: "info",
    },
    iconColor: {
      type: String,
      default: "secondary",
    },
  },
};
</script>

<style scoped></style>
