<template>
  <v-chip
    v-if="isEnvStagging"
    class="warning d-flex justify-center black--text rounded-10"
  >
    Ambiente de testes
  </v-chip>
</template>

<script>
export default {
  data: () => ({
    env: "",
  }),
  computed: {
    isEnvStagging() {
      return this.env === "stag";
    },
  },
  created() {
    this.env = process.env.VUE_APP_ENV;
  },
};
</script>

<style></style>
