import Vue from "vue";
import Vuex from "vuex";

import loading from "@/store/loading-module";
import auth from "@/store/auth-module";
import pares from "@/store/pares-module";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const config = {
  modules: {
    loading,
    auth,
    pares,
  },
  strict: debug,
};

export default new Vuex.Store(config);
