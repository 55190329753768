<template>
  <div>
    <v-expansion-panels v-model="panel" flat class="elevation-1">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Filtros para buscar
        </v-expansion-panel-header>
        <v-divider />

        <v-expansion-panel-content>
          <slot></slot>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  data: () => ({
    panel: false,
  }),
};
</script>

<style></style>
