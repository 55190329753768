import Vue from "vue";
import { isValidCpf } from "@brazilian-utils/validators";

Vue.prototype.$rules = {
  required: (v) => !!v || v === 0 || "Campo obrigatório",
  requiredTextarea: (v) => !!v.trim() || v === 0 || "Campo obrigatório",
  requiredArray: (v) => (!!v && v.length > 0) || "Campo obrigatório",
  email: (v) => /.+@.+/.test(v) || "E-mail inválido",
  confirmPassword(password1, password2) {
    return () => password1 == password2 || "Campos de senha não são iguais";
  },
  cpf: (v) => isValidCpf(v) || "CPF inválido",
};
